<template>
  <backoffice-container>
    <b-card no-body :class="isMobile ? 'p-2' : 'p-3'" :style="isMobile
      ? 'min-height: calc(100vh - 100px)'
      : 'min-height: calc(100vh - 125px)'
      ">
      <div class="d-flex flex-wrap justify-content-between ml-50 mb-2">
        <h4>{{ $t("backoffice.sepa.title") }}</h4>
        <div>
          <b-button variant="primary" @click="showModal = true" class="ml-1">
            {{ $t("backoffice.sepa.import-members") }}
          </b-button>
          <b-button variant="primary" class="ml-1" @click="generateSepaXML">
            {{ $t("backoffice.sepa.generate-xml") }}
          </b-button>
          <b-button variant="primary" class="ml-1" @click="showSepaForm = true">
            {{ $t("backoffice.sepa.sepa-form") }}
          </b-button>
        </div>
      </div>

      <!-- Loading spinner -->
      <div v-if="isLoading" class="h-100 d-flex align-items-center">
        <b-spinner class="d-block mx-auto mt-3" variant="primary" />
      </div>

      <!-- SEPA files table -->
      <div v-else>
        <div v-if="sepaFiles && sepaFiles.length > 0">
          <app-scroll>
            <template>
              <div class="w-100 p-0 overflow-hidden">
                <b-table :items="sepaFiles" :fields="tableFields" responsive show-empty
                  class="position-relative mx-auto" primary-key="iban" empty-text="No matching records found"
                  per-page="15" :table-class="sepaFiles.length < 5 ? 'mb-5' : ''">
                  <!-- Actions column -->
                  <template #cell(actions)="data">
                    <b-dropdown id="dropdown-dropleft" variant="link" no-caret>
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" />
                      </template>

                      <b-dropdown-item @click="editSepaFile(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        {{ $t('backoffice.roles.permissions.update') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="confirmDelete(data.item)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        {{ $t('backoffice.roles.permissions.delete') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="generateIndividualSepaXML(data.item)">
                        <feather-icon icon="FileIcon" class="mr-50" />
                        {{ $t('backoffice.sepa.generate-xml') }}
                      </b-dropdown-item>

                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </template>
          </app-scroll>
        </div>

        <!-- Empty state -->
        <div v-else class="d-flex placeholder align-item-center justify-content-center flex-column my-2"
          style="min-height: 45vh">
          <b-img :src="paymentsPlaceholder" class="placeholder-img" center />
          <div class="mt-2 text-center">
            <p class="text-primary font-weight-500">
              {{ $t("backoffice.sepa.no-files") }}
            </p>
          </div>
        </div>
      </div>

      <!-- Loading modal -->
      <loading-modal v-model="isGenerating" v-ripple.400="'rgba(234, 84, 85, 0.15)'" />

      <!-- import member modal -->
      <b-modal :visible="showModal" @hide="showModal = false" title="Import member" @hidden="resetMember">



        <div class="d-flex align-items-start justify-content-between">
          <div>
            <b-button variant="link" class="p-0 mt-40 pointer font-weight-bold">
              {{ $t("backoffice.sepa.import-members") }}
            </b-button>
            <file-upload v-if="uploadFileVisible" v-model="csvFile" type="csv" class="w-100 mt-1" cancelable
              :placeholder="$t('media.form.file.placeholder')"
              :drop-placeholder="$t('media.form.file.drop-placeholder')" />
          </div>

        </div>
        <div class="my-2">
          <b-alert show variant="warning">{{ $t("backoffice.sepa.warning") }}</b-alert>
        </div>

        <template #modal-footer>
          <div class="d-flex w-100 align-items-center justify-content-between">
            <div>
              <b-badge variant="light-primary" class="ml-25 mt-50 font-weight-normal pointer"
                @click="downloadCsv()"><feather-icon icon="DownloadIcon" class="mr-25" />{{
                  $t("download.csv")
                }}</b-badge>
            </div>
            <div>
              <b-button :disabled="isImportMemberLoading" variant="primary" @click="handleInviteMember">

                {{ isImportMemberLoading ? `${$t("backoffice.sepa.Importing")}...` : $t("backoffice.sepa.importFile") }}
                <b-spinner v-if="isImportMemberLoading" small type="grow"></b-spinner></b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <!-- SEPA form modal -->
      <template>
        <b-modal id="sepa-form" :visible="showSepaSaveForm" size="md" ok-only @hide="showSepaSaveForm = false"
          @hidden="reset()" title="SEPA form">
          <!-- <template>
            <h5 class="modal-title">SEPA form</h5>
            <b-button variant="close" @click="showSepaForm = false" aria-label="Close"></b-button>
          </template> -->

          <div>
            <b-form>
              <b-form-group label="Name" label-for="name-input">
                <b-form-input id="name-input" v-model="form.name" required placeholder="Enter name"></b-form-input>
              </b-form-group>

              <b-form-group label="IBAN" label-for="iban-input">
                <b-form-input id="iban-input" v-model="form.iban" required placeholder="Enter IBAN"></b-form-input>
              </b-form-group>

              <b-form-group label="BIC" label-for="bic-input">
                <b-form-input id="bic-input" v-model="form.bic" required placeholder="Enter BIC"></b-form-input>
              </b-form-group>

              <b-form-group label="Amount" label-for="amount-input">
                <b-form-input id="amount-input" v-model="form.amount" type="number" required
                  placeholder="Enter amount"></b-form-input>
              </b-form-group>

              <b-form-group label="Remittance Info" label-for="remittance-input">
                <b-form-input id="remittance-input" v-model="form.remittance_info" required
                  placeholder="Enter remittance info"></b-form-input>
              </b-form-group>
            </b-form>
          </div>

          <template #modal-footer>
            <b-button :disabled="!isFormValid || sepaSaveButton" variant="primary"
              @click="updateSepaForm">Save</b-button>
          </template>
        </b-modal>
      </template>

      <!-- delete table item -->
      <b-modal v-if="showDeleteModal" :visible="showDeleteModal" @hide="closeDeleteModal" title="Confirm Delete">
        <p>Are you sure you want to delete <span class="font-weight-bolder">{{ this.deleteItemData.name }} </span> SEPA
          order?
        </p>
        <template #modal-footer>
          <!-- <b-button variant="secondary" @click="closeDeleteModal">Cancel</b-button> -->
          <b-button variant="danger" :disabled="isDeletedModalLoading" @click="deleteSepaItem">
            {{ isDeletedModalLoading ? `${$t("backoffice.sepa.deleting")}...` : $t("backoffice.sepa.delete") }}

            <b-spinner v-if="isDeletedModalLoading" small type="grow"></b-spinner>

          </b-button>
        </template>
      </b-modal>

    </b-card>
  </backoffice-container>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue"
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin"
import PaymentsPlaceholder from "@/assets/images/placeholders/light/payments.svg"
import LoadingModal from "@core/components/modal/LoadingModal.vue"
import FileUpload from "@core/components/files/FileUpload.vue";
import { format } from 'date-fns'
import Service from '@/config/service-identifiers';
import $service from '@/config/container';

export default {
  name: "BackofficeSepa",
  components: {
    BackofficeContainer,
    LoadingModal,
    FileUpload
  },
  directives: {
    Ripple,
  },
  mixins: [ToastNotificationsMixin],

  data() {
    return {
      sepaSaveButton: false,
      showSepaSaveForm: false,
      uploadFileVisible: true,
      showModal: false,
      isLoading: true,
      isGenerating: false,
      isMobile: false,
      csvFile: null,
      showSepaForm: false,
      showDeleteModal: false,
      deleteItemData: null,
      form: {
        name: "",
        iban: "",
        bic: "",
        amount: "",
        remittance_info: "",
      },
      sepaFiles: [],
      tableFields: [
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "iban", label: "IBAN" },
        { key: "bic", label: "BIC" },
        { key: "amount", label: "Amount" },
        { key: "remittance_info", label: "Remittance Info   " },
        { key: "actions", label: "Action" }
      ],
      csvTemplateEmails: [
        ["name", "email", "iban", 'bic', 'amount', 'remittance_info'],
        ["Name1", "name@xyz.com", "BG18RZBB91550123456789acc", "XMPLDEM0XXX", 200, "example"],
      ],
      isImportMemberLoading: false,
      isDeletedModalLoading: false,
    }
  },

  computed: {
    resetMember() {
      this.csvFile = null;
      this.showModal = false;
    },
    reset() {
      this.form = {
        name: "",
        iban: "",
        bic: "",
        amount: "",
        remittance_info: "",
      }
      this.showSepaForm = false
    },
    currentCollective() {
      return this.$store.getters.currentCollective?.slug
        ? this.$store.getters.currentCollective
        : null
    },
    paymentsPlaceholder() {
      return PaymentsPlaceholder
    },
    isFormValid() {
      return (
        this.form.name &&
        this.form.iban &&
        this.form.bic &&
        this.form.amount &&
        this.form.remittance_info
      );
    },
  },

  created() {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
    this.fetchSepaData()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
  },

  methods: {

    downloadCsv() {
      let csv = "";
      this.csvTemplateEmails.forEach((row) => {
        csv += row;
        csv += "\r\n";
      });
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = "CSV_template_members.csv";
      hiddenElement.click();
    },
    confirmDelete(item) {
      this.deleteItemData = item;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.deleteItemData = null;
      this.showDeleteModal = false;
    },
    async deleteSepaItem() {
      if (this.deleteItemData) {
        this.isDeletedModalLoading = true;
        try {
          const res = await this.$store.$service[Service.BackendClient].post(
            '/backoffice/sepa/delete/member',
            {
              communityKey: this.currentCollective?.key,
              sepaMemberId: this.deleteItemData.id
            }
          )
          if (res.status === 200) {
            await this.fetchSepaData();
            this.closeDeleteModal();
            this.notifySuccess(this.$t('backoffice.sepa.success.deleted'))
          }

        } catch (error) {
          this.notifyError(this.$t('backoffice.sepa.errors.delete'))
        }
        this.isDeletedModalLoading = false

      }
    },
    editSepaFile(item) {
      this.form = { ...item };
      this.showSepaSaveForm = true;
    },
    async updateSepaForm() {
      this.sepaSaveButton = true
      try {
        const response = await this.$store.$service[Service.BackendClient].post(
          '/backoffice/sepa/edit/member',
          {
            communityKey: this.currentCollective.key,
            payload: this.form,
          }
        )
        if (response.status === 200) {
          this.notifySuccess(this.$t('backoffice.sepa.success.updated'))
          this.showSepaSaveForm = false
          await this.fetchSepaData();
          this.form = {
            name: "",
            iban: "",
            bic: "",
            amount: "",
            remittance_info: "",
          }
        }
      } catch (error) {
        this.notifyError(this.$t('backoffice.sepa.errors.updated'))
      }
      this.sepaSaveButton = false


    },
    async handleInviteMember() {
      if (this.csvFile !== null) {
        this.isImportMemberLoading = true

        try {
          const formData = new FormData();

          const requestData = {
            communitySlug: this.currentCollective?.slug,
          };
          formData.append('file', this.csvFile);
          formData.append('data', JSON.stringify(requestData));
          const response = await $service[Service.Imports].post(
            '/backoffice/sepa/members/import',
            formData,
            {
              headers: { 'content-type': 'multipart/form-data' },
            },
          )
          if (response.status === 200) {
            this.notifySuccess(this.$t('backoffice.sepa.success.saved'))
            await this.fetchSepaData();
            this.showModal = false;
            this.csvFile = null
            this.isImportMemberLoading = false
          }
        } catch (error) {
          this.csvFile = null
          this.isImportMemberLoading = false
          this.notifyError(this.$t('backoffice.sepa.errors.fetch'))
        }


      }
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 700
    },

    async fetchSepaData() {
      this.isLoading = true
      try {
        const response = await this.$store.$service[Service.BackendClient].get(
          '/backoffice/sepa',
          {
            params: {
              communityKey: this.currentCollective.key
            }
          }
        )
        this.sepaFiles = response.data.data
      } catch (error) {
        this.sepaFiles = []
      }
      this.isLoading = false
    },

    async generateSepaXML() {
      this.isGenerating = true
      try {
        const response = await this.$store.$service[Service.BackendClient].get(
          '/backoffice/sepa/generate/all/members/xml',
          {
            params: {
              communityKey: this.currentCollective.key
            }
          }
        )
        const blob = new Blob([response.data], { type: 'application/xml' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'sepa_payment.xml';
        link.click();
        URL.revokeObjectURL(link.href); 
        this.notifySuccess(this.$t('backoffice.sepa.success.generated'))
        await this.fetchSepaData()
      } catch (error) {
        console.log('error', error)
        this.notifyError(this.$t('backoffice.sepa.errors.generate'))
      }
      this.isGenerating = false
    },

    async generateIndividualSepaXML(item) {
      console.log('item', item)
      this.isGenerating = true
      try {
        const response = await this.$store.$service[Service.BackendClient].get(
          '/backoffice/sepa/generate/member/xml',
          {
            params: {
              communityKey: this.currentCollective.key,
              memberId: item.id 
            }
          }
        )
        const blob = new Blob([response.data], { type: 'application/xml' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'sepa_payment.xml';
        link.click();
        URL.revokeObjectURL(link.href); 
        this.notifySuccess(this.$t('backoffice.sepa.success.generated'))
        await this.fetchSepaData()
      } catch (error) {
        this.notifyError(this.$t('backoffice.sepa.errors.generate'))
      }
      this.isGenerating = false
    },

    async downloadSepaFile(file) {
      try {
        const response = await this.$store.$service.BackendClient.get(
          `/backoffice/sepa/download/${file.id}`,
          {
            responseType: 'blob'
          }
        )

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        this.notifyError(this.$t('backoffice.sepa.errors.download'))
      }
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
.placeholder-img {
  width: 180px;
  display: block;
}
</style>
